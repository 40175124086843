#modals {
	justify-content: center;
	position: fixed;
	margin: 0;
	padding: 0;
	top: 0;
	left: 0;
	width: 100vw;
	height: 0;
	z-index: 1001;
	overflow: hidden;
	background: rgba(255, 255, 255, 0.85);
	transition: width var(--animation-time), height var(--animation-time);
}

#mobile-nav-bar.opened {
	transition: width var(--animation-time), height var(--animation-time);
}

#mobile-nav-bar {
	transition: width var(--animation-time), height var(--animation-time);
}

#mobile-nav-bar .mobile-nav-menu {
	transition: width var(--animation-time), height var(--animation-time);
}

#modals.visible {
	width: 100vw;
	height: 100vh;
}

body.modal-visible {
	overflow-x: hidden;
}

.modal-chrome {
	position: relative;
	top: var(--spacing-m);
	background: rgb(255, 255, 255);
	box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.16);
}

.modal-chrome .modal-bar {
	height: 7.2rem;
	justify-content: flex-end;
}


/* .barcode-scanner-camera {
	
}

.barcode-scanner-camera::before {
	
} */

#mobile-nav-bar {
	border-Bottom: 0.1rem solid #c7d4da;
}

#mobile-nav-bar .mobile-nav-menu-buttons.menuHidden {
	justify-content: flex-end;
}

@media (max-width: 767px) {

	#mobile-nav-bar .mobile-nav-menu-header {
		width: 28.8rem;
	}

	#mobile-nav-bar .mobile-nav-menu {
		width: 28.8rem;
	}

	#mobile-nav-bar .button.chromeless {
		padding: 0;
	}

}

@media (min-width: 768px) and (max-width: 1279px) {
	#mobile-nav-bar .mobile-nav-menu-header {
		width: 70rem;
	}

	#mobile-nav-bar .mobile-nav-menu {
		width: 70rem;
	}

	#mobile-nav-bar .mobile-nav-menu-buttons {
		justify-content: space-between;
		width: 37.5rem;
	}
	
	#mobile-nav-bar .button.chromeless {
		padding: 0.8rem 0 0 0.4rem;
	}

}


@media (max-width: 1279px) {
	#mobile-nav-bar {
		top: 0;
		position: fixed;
		background: white;
		z-index: 100;
		width: 100vw;
		height: 73px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	#mobile-nav-bar.opened {
		height: 100vh;
		transition: width var(--animation-time), height var(--animation-time);
	}

	#mobile-nav-bar .mobile-nav-menu {
		position: absolute;
		top: 72px;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background: white;
		height: 0;
	}

	#mobile-nav-bar.opened .mobile-nav-menu {
		top: 72px;
		height: 100vh;
	}

	#mobile-nav-bar .mobile-nav-menu a {
		padding: 1.6rem 0 0.8rem;
	}

	#mobile-nav-bar .mobile-nav-menu-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 72px;
	}

	#root {
		margin-top: 72px;
	}
}
