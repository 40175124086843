#webcam-window {
  position: absolute;
  border: 70px solid rgba(0,0,0,0.7);
  /* background-color: white; */
  width: 300px;
  height: 300px;
  /* top: 25%; */
  z-index: 99999999;
}


