@media (max-width: 767px) {
	.homepage.hero {
		background-image: url('./assets/hero.svg');
		background-repeat: no-repeat;
		background-position: bottom -6rem center;
		background-size: 45rem;

		padding-top: 2.8rem;
		padding-bottom: 6.4rem;
	}

	.fucking-triangle {
		position: relative;
		top: 80px;
		left: 35%;
		width: 0;
		height: 0;
		border-left: 30px solid transparent;
		border-right: 30px solid transparent;
		border-bottom: 60px solid #b1cede;
		z-index: 10;
		transform: rotate(-23deg);
	}

	.usp.icon.container {
		justify-content: center;
	}

	.homepage.alternative {
		background-color: rgb(252, 245, 239);
	}

	div.about-us {
		padding: 3.2rem 0 !important;
	}

	.about-us.hero {
		padding-top: 7.2rem;
		background-image: url('./assets/blue-background-mobile.svg');
		background-repeat: repeat-x;
		background-position: top left;
	}

	/****************
	 * For Business *
	 ****************/

	.for-businesses h1 {
		text-align: left;
	}

	.marketing-border {
		border-radius: 12px;
		border: solid 1px #c7d4da;
		padding: 7.15rem 2.15rem;
		flex-direction: column;
	}

	/****************************
	 * For Health Practitioners *
	 ****************************/

	.for-hp.hero {
		padding-top: 7.2rem !important;
		background-image: url('./assets/background-blue-for-hp-mobile.svg');
		background-repeat: repeat-x;
		background-position: top left;
	}

	/********************
   * Kit Instructions *
   ********************/

	.ki.instruction-explanation {
		align-items: center;
		padding-right: 0;
	}
}
