#hamburger-icon,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
	width: 17px;
	height: 24px;
	position: relative;
	margin: 50px auto;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
}

#hamburger-icon span {
	display: block;
	position: absolute;
	height: 1px;
	width: 100%;
	background: black;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}

#hamburger-icon span:nth-child(1) {
	top: 8px;
}

#hamburger-icon span:nth-child(2) {
	top: 16px;
}

#hamburger-icon.open span:nth-child(1) {
	top: 12px;
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
}

#hamburger-icon.open span:nth-child(2) {
	top: 12px;
	-webkit-transform: rotate(-135deg);
	-moz-transform: rotate(-135deg);
	-o-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
