div.marketing {
	padding: 3.2rem 0;
	justify-content: center;
}

.Oval-Copy-7 {
	width: 64px;
	height: 64px;
	background-color: #e6f2f8;
  }


.homepage.hero {
	background-image: url('./assets/hero.svg');
	background-repeat: no-repeat;
	background-position: bottom -11rem right 0;
	background-size: 94.2rem;
}

.beige-plane-background {
	background-image: url('./assets/illustration-beige-plane.svg');
	background-repeat: no-repeat;
	background-position: top 0 right 0;
	background-size: 24.5rem;
}

.fucking-triangle {
	position: relative;
	top: 285px;
	left: -3%;
	width: 0;
	height: 0;
	border-left: 30px solid transparent;
	border-right: 30px solid transparent;
	border-bottom: 60px solid #b1cede;
	z-index: 10;
	transform: rotate(-23deg);
}

.antibody-indication-background {
	background-image: url('./assets/antibody-indication.svg');
	background-repeat: no-repeat;
	background-position: bottom 0 right 3.2rem;
	background-size: 1.9rem;
}

/******************
 * For Businesses *
 ******************/

.for-businesses.alternative {
	background-color: rgb(252, 245, 239);
	padding: 7.2rem !important;
}



/****************************
 * For Health Practitioners *
 ****************************/

.for-hp.hero {
	padding-top: 7.2rem !important;
	background-image: url('./assets/background-blue-for-hp.svg');
	background-repeat: repeat-x;
	background-position: top left;
}

.scientist-background {
	background-image: url('./assets/earn-graph-1.svg');
	background-repeat: no-repeat;
	background-position: center center;
}

.for-hp.alternative {
	background-color: rgb(252, 245, 239);
	padding: 7.2rem !important;
}

/********************
 * Kit Instructions *
 ********************/

.ki.hero {
	padding-top: 7.2rem !important;
	background-color: #e6f2f8;
}

.ki.alternative {
	background-color: rgb(252, 245, 239);
	padding: 7.2rem !important;
}

.ki.instruction-explanation {
	align-items: flex-end;
	padding-right: 3.2rem;
}

.ki.instruction-explanation2 {
	align-items: flex-end;
	width: 10px;
	padding-right: 3.2rem;
}
