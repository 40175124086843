@media (min-width: 768px) and (max-width: 1279px) {
	.homepage.hero {
		background-image: url('./assets/hero.svg');
		background-repeat: no-repeat;
		background-position: bottom -6rem right 0;
		background-size: 48.5rem;

		padding-top: 2.8rem;
		padding-bottom: 13.2rem;
	}

	.fucking-triangle {
		position: relative;
		top: 160px;
		left: 50%;
		width: 0;
		height: 0;
		border-left: 30px solid transparent;
		border-right: 30px solid transparent;
		border-bottom: 60px solid #b1cede;
		z-index: 10;
		transform: rotate(-23deg);
	}

	.homepage.alternative {
		background-color: rgb(252, 245, 239);
		background-image: url('./assets/illustration-beige-cloud.svg');
		background-repeat: no-repeat;
		background-position: top 30% left 50%;
		background-size: 62.7rem;
	}

	.usp.icon {
		width: 8.3rem;
		height: 8.3rem;
	}

	.tablet.instructions-background {
		background-image: url('./assets/throat-swab.svg'), url('./assets/nose-swab.svg');
		background-repeat: no-repeat, no-repeat;
		background-position: top 1rem left 1rem, top 1rem right 1rem;
		background-size: 30.5rem, 30.5rem;
		min-height: 32.5rem;
	}

	.about-us.hero {
		padding-top: 7.2rem;
		background-image: url('./assets/blue-background.svg');
		background-repeat: repeat-x;
	}

	/****************
	 * For Business *
	 ****************/

	.for-businesses.hero {
		padding-top: 7.2rem !important;
		background-image: url('./assets/background-blue-for-businesses.svg');
		background-repeat: repeat-x;
		background-position: top left;
	}

	.marketing-border {
		border-radius: 12px;
		border: solid 1px #c7d4da;
		padding: 7.25rem 5.2rem;
		flex-direction: column;
	}


}
