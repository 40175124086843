
.signUpBtn{
    color: royalblue;
    text-decoration: underline;
    margin-left: 12px;
}

.backgroundLogin{
    background-image: url("../../assets/images/login1.jpg");
    background-size: cover;
    min-height: 100vh;
}

.backgroundSignup{
    background-image: url("../../assets/images/login21.jpg");
    background-size: cover;
    background-position: top;
    min-height: 100vh;
}