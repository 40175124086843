a.link-button {
	text-decoration: none;
}

.fitted.button {
	width: fit-content;
}

.spread.button {
	width: 100%;
}

.img-button {
	cursor: pointer;
}

input[type='submit'] {
	border: 0;
}

.tiny.button {
	font-size: 1.2rem;
	height: 2.4rem;
}

input[type='submit'],
.button {
	margin: var(--spacing-xs);
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 2rem;
	height: 4rem;
	border-radius: 2rem;
	background-color: var(--color-teal);
	font-size: 1.6rem;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	justify-content: center;
	color: rgb(255, 255, 255);
	cursor: pointer;
}

.button.chromeless {
	border: none;
	border-radius: 0;
	margin: 0;
	background: none;
	color: black;
}

.chromeless.dangerous {
	color: var(--color-dangerous);
}

.button.dangerous {
	color: var(--color-dangerous);
	border: solid 1px var(--color-dangerous);
	background: none;
}

.button.alternative {
	background-color: var(--color-orange);
}

.button.primary {
	background-color: var(--color-blue);
}

.button.link {
	color: var(--color-blue);
}

.button.secondary {
	color: var(--color-blue);
	font-size: 1.4rem;
	border: solid 1px var(--color-blue);
	background: none;
}

.button.icon {
	padding-left: 0;
}

.column-header {
	padding: 0 2.5rem;
}

.disabled {
	opacity: 0.3;
	cursor: not-allowed;
}


