:root {
	--color-black: rgb(0, 0, 0);
	--color-teal: rgb(7, 191, 193);
	--color-blue: rgb(6, 122, 184);
	--color-bluegrey: rgb(199, 212, 218);
	--color-grey: rgb(127, 127, 127);
	--color-orange: rgb(255, 117, 0);
	--color-beige: rgb(253, 245, 238);
	--color-dangerous: #cb0f2a;
	--color-highlight: #60717c;

	--spacing-xs: 0.4rem;
	--spacing-s: 0.8rem;
	--spacing-m: 3.2rem;
	--spacing-xl: 8rem;

	--animation-time: 0.3s;
}

html {
	font-size: 62.5%;
	font-family: 'Open Sans', OpenSans, sans-serif;

}

body {
	overflow-x: hidden;
    font-family: inherit !important;
}

div,
button,
body,
html {
	padding: 0;
	border: 0;
	margin: 0;
}
#kit-page .pagination {
	 display: flex; 
	 list-style-type: none;
}
#kit-page .pagination li {
	padding: 5px;
	cursor: pointer;
}

div {
	/* display: flex; */
	box-sizing: border-box;
}

.app {
	display: flex;
	background: #ffffff;
	min-height: 100vh;
}

.footer.segment {
	background-color: #e6f2f8;
	margin-top: 50px;
}

.app #main-column {
	/*margin: 0 0 8rem 0;*/
}

.app #main-column.page-for-businesses, .app #main-column.page-for-health-practitioners, .app #main-column.page-test-kit-instructions {
	margin-bottom: 0rem;
}


.footer.segment span.body.small {
	line-height: 1.28;
}


div.header.navigation {
	padding: 1.7rem 0;
	border-bottom: 0.1rem solid #c7d4da;
}

.bordered {
	border-top: 0.1rem solid #c7d4da;
}

a.small {
	font-size: 1.2rem;
}

a.header.navigation {
	font-size: 1.6rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #000000;
	text-decoration: none;
}


a.header.navigation.active {
	font-weight: 600;
}


a.footer.navigation {
	font-size: 1.6rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: var(--color-blue);
	text-decoration: none;
}

a {
	font-size: 1.6rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
}

div.information {
	padding: 2.6rem;
	border-radius: 12px;
	border: solid 1px #c7d4da;
}

.tablet-and-desktop .nav-menu-buttons {
	align-items: center;
	justify-content: space-between; 
	width: 109rem;
}

.tablet-and-desktop .nav-menu-buttons.menuHidden {
	justify-content: flex-end;
}

@media (min-width: 1280px) {
	.not-desktop,
	.mobile,
	.tablet {
		display: none !important;
		width: 0 !important;
		height: 0 !important;
	}

	div.page-section.swab-description {
		flex-direction: row-reverse;
	}
}

@media (min-width: 768px) and (max-width: 1279px) {
	.not-tablet,
	.desktop,
	.mobile {
		display: none !important;
		width: 0 !important;
		height: 0 !important;
	}

	div.page-section.swab-description {
		flex-direction: row-reverse;
		flex-wrap: wrap;
	}

	a.header.navigation {
		border-bottom: 1px solid var(--color-blue);
	}
}

@media (max-width: 767px) {
	.not-mobile,
	.desktop,
	.tablet {
		display: none !important;
		width: 0 !important;
		height: 0 !important;
	}

	a.header.navigation {
		border-bottom: 1px solid var(--color-bluegrey);
	}

	.tablet-and-desktop {
		display: none;
	}
}

.imageSubmission{
	background-image: url('./assets/images/image_submission.png');
	background-repeat: no-repeat;
}