.MuiPaper-rounded {
    width: 100%;
}

.absolute-input {
    position     : absolute;
    top          : 73px;
    right        : 0;
    width        : 98% !important;
    transition   : height 2s;
    background   : lightblue;
    border-radius: 5px;
    overflow-y   : auto;
    z-index      : 2;
}

.absolute-left-2 {
    left: 7px;
}

.absolute-left {
    left: 4px;
}

.absolute-sucess {
    height: 340px;
}

.absolute-error {
    height: 140px;
}

.item-style {
    color      : black;
    font-weight: 500;
    font-size  : 14px;
    cursor     : pointer;
    padding    : 8px 12px;
}

.error-style {
    color      : darkred;
    text-align : center;
    font-weight: 500;
    font-size  : 14px;
    cursor     : pointer;
    padding    : 8px 12px;
}

.item-style:hover {
    color     : white;
    background: #0047AB;
}

.animateSearchUpload {
    animation: 0.3s ease-out 0s 1 slideInSearch;
    /*	duration --- type --- delay --- number of times --- custom name*/
}

@keyframes slideInSuccessSearch {
    0% {
        height: 0;
    }

    100% {
        height: 400px;
    }
}

.animateSearchUploadError {
    animation: 0.3s ease-out 0s 1 slideInSuccessSearchError;
    /*	duration --- type --- delay --- number of times --- custom name*/
}

@keyframes slideInSuccessSearchError {
    0% {
        height: 0;
    }

    100% {
        height: 140px;
    }
}

::-webkit-scrollbar {
    width : 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background-color     : lightgray;
    -webkit-border-radius: 10px;
    border-radius        : 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius        : 10px;
    background           : darkslateblue;
}