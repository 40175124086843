.lds-heart {
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;
	transform: rotate(45deg);
	transform-origin: 20px 20px;
}

.lds-heart div {
	top: 14px;
	left: 14px;
	position: absolute;
	width: 16px;
	height: 16px;
	background: #fff;
	animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
	content: ' ';
	position: absolute;
	display: block;
	width: 16px;
	height: 16px;
	background: #fff;
}
.lds-heart div:before {
	left: -12px;
	border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
	top: -12px;
	border-radius: 50% 50% 0 0;
}

@keyframes lds-heart {
	0% {
		transform: scale(0.95);
	}
	5% {
		transform: scale(1.1);
	}
	39% {
		transform: scale(0.85);
	}
	45% {
		transform: scale(1);
	}
	60% {
		transform: scale(0.95);
	}
	100% {
		transform: scale(0.9);
	}
}

.lds-heart.orange div {
	background: var(--color-orange);
}

.lds-heart.orange div:after,
.lds-heart.orange div:before {
	background: var(--color-orange);
}
