.date-input-container {

    position: relative;

    .day-text {
        position: absolute;
        top     : 12.25px;
        left    : 85px;
        z-index : 1;
    }

    .MuiFormControl-root {

        margin          : 0px !important;
        background-color: white;
        // border: 1px solid #cacaca;
        border-radius   : 3px;
        box-shadow      : 0px 8px 8px 0 rgba(0, 0, 0, 0.1);
        width           : 100%;
        transition      : border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        height          : 42px;
        margin-top      : -3px;

        .MuiOutlinedInput-input {
            padding        : 0px 10px;
            height         : 45px;
            display        : flex;
            align-items    : center;
            justify-content: flex-start;
        }

        .MuiOutlinedInput-notchedOutline {
            border: none !important;
        }

        .MuiFormHelperText-root.Mui-error {
            display: none;
        }

    }
}

.MuiPopover-paper {
    max-width: 320px !important;
}