@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Open Sans Regular'), local('OpenSans-Regular'),
		url(OpenSans-Regular.ttf) format('ttf');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
		url(OpenSans-SemiBold.ttf) format('ttf');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local('Open Sans Bold'), local('OpenSans-Bold'), url(OpenSans-Bold.ttf) format('ttf');
}

/* latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
		url(OpenSans-ExtraBold.ttf) format('ttf');
}
