@media (min-width: 1280px) {
	.homepage.hero {
		padding-top: 19.2rem;
	}

	.homepage.alternative {
		background-image: url('./assets/illustration-beige-cloud.svg'),
			url('./assets/beige-background.svg');
		background-repeat: no-repeat, repeat-x;
		background-position: top 50% left -14rem, top left;
		background-size: 48.7rem, 0.2rem 70.3rem;
		padding-top: 7.4rem;
	}

	.usp.icon {
		width: 8.4rem;
		height: 8.4rem;
	}

	.desktop.instructions-background {
		background-image: url('./assets/throat-swab.svg'), url('./assets/nose-swab.svg');
		background-repeat: no-repeat, no-repeat;
		background-position: top 1rem left 1rem, bottom 1rem right 1rem;
		background-size: 37rem, 35.6rem;
		min-height: 50rem;
	}

	.marketing-border {
		border-radius: 12px;
		border: solid 1px #c7d4da;
		padding: 7.15rem 22.15rem;
		flex-direction: column;
	}

	/************
	 * About US *
	 ************/

	.about-us.hero {
		padding-top: 7.2rem;
		background-image: url('./assets/blue-background.svg');
		background-repeat: repeat-x;
	}

	.about-us.secure-and-accurate {
		margin-top: 3.2rem !important;
	}

	.about-us .programmer-img {
		width: 34.9rem;
		height: 31.7rem;
	}

	.about-us .doctors-img-container {
		margin-right: 1.6rem !important;
	}

	.about-us .doctors-img {
		width: 28.7rem;
		height: 44.6rem;
	}

	.about-us .secure-and-accurate-icon {
		width: 153px;
		height: 156px;
	}

	/****************
	 * For Business *
	 ****************/

	div.for-businesses {
		padding: 3.2rem 0 !important;
	}

	.for-businesses.hero {
		padding-top: 7.2rem !important;
		background-image: url('./assets/background-blue-for-businesses.svg');
		background-repeat: repeat-x;
		background-position: top left;
	}

	.for-businesses .group-img {
		width: 125.2rem;
		height: 37.8rem;
	}

}
