input[type='checkbox'] {
	margin: 0 1.1rem 0 0;
	width: 1.6rem;
	height: 1.6rem;
	border-radius: 0.2rem;
	border: solid 1px #789aa5;
	background-color: #ffffff;
}

input[type='radio'] {
	margin: 0;
	margin-right: var(--spacing-s);
	width: 1.6rem;
	height: 1.6rem;
}

textarea:focus,
input:focus {
	outline: none;
}

textarea.form-component-border,
div.form-component-border {
	border: solid 0.1rem #789aa5;
	border-radius: 0.3rem;
}

textarea.form-component-border.invalid,
div.form-component-border.invalid {
	border: solid 0.2rem var(--color-dangerous);
}

input.form-component {
	height: 4rem;
	width: 100%;
	margin: 0 var(--spacing-s);
	border: 0;
	background-color: #ffffff;
	font-size: 1.6rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #000000;
}

textarea.form-component {
	margin: 0;
	background-color: #ffffff;
	font-size: 1.6rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #000000;
}

label.form-component.large {
	font-size: 1.6rem;
}

label.form-component {
	font-size: 1.2rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 2;
	letter-spacing: normal;
	color: #000000;
}

a.form-component {
	font-size: 1.2rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 2;
	letter-spacing: normal;
}

select {
	height: 4rem;
	border-radius: 0.3rem;
	border: solid 1px #789aa5;
	background-color: #ffffff;

	font-family: Open Sans;
	font-size: 1.6rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: right;
	color: #000000;
}
