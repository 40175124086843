span,
p,
label,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: Open Sans;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 1.6rem 0;
}

h1 {
	font-size: 4.2rem;
	font-weight: 600;
	line-height: 1.14;
	color: var(--color-blue);
}

h1.huge {
	font-size: 64px;
}

h3 {
	font-size: 2.8rem;
	font-weight: 600;
	margin: calc(var(--spacing-s) * 2) 0;
	color: var(--color-blue);
}

h1.no-space,
h2.no-space,
h3.no-space,
h4.no-space,
h5.no-space {
	margin: 0;
}

h4 {
	font-size: 2rem;
}

h5 {
	font-size: 1.2rem;
	font-weight: bold;
	text-transform: uppercase;
}

a {
	color: var(--color-blue);
	text-decoration: none;
}

@media (max-width: 767px) {
	h1 {
		font-size: 2.8rem;
	}
}

span.body {
	font-size: 1.6rem;
}

ol {
	padding: 2rem;
}

ol li {
	font-size: 1.6rem;
	line-height: 1.5;
}

span.body.small {
	font-size: 1.2rem;
	line-height: 20px;
}

.black {
	color: var(color-black);
}

.blue {
	color: var(color-blue);
}

.bold {
	font-weight: bold;
}

.error {
	color: var(--color-dangerous);
}

.paragraph {
	padding: var(--spacing-s) 0;
}

.centered {
	text-align: center;
}

@media (max-width: 767px) {
	h1.huge {
		font-size: 42px;
	}
}
