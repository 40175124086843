div.container-app.column-app {
	flex-direction: column;
}

div.container-app.row-app {
	flex-direction: row;
}

div.container-app:last-child {
	margin-right: 0;
}

div.bordered {
	border-radius: 1.2rem;
	border       : solid 0.1rem #c7d4da;
	padding      : var(--spacing-s);
}

.bordered-as-list:only-child {
	border-radius: 1.2rem;
	border       : solid 0.1rem #c7d4da;
}

.bordered-as-list:first-child {
	border-radius             : 1.2rem;
	border-bottom-left-radius : 0;
	border-bottom-right-radius: 0;
	border                    : solid 1px #c7d4da;
}

.bordered-as-list {
	border    : solid 1px #c7d4da;
	border-top: none;
	padding   : 1.6rem;
}

.bordered-as-list:last-child {
	border-radius          : 1.2rem;
	border-top-left-radius : 0;
	border-top-right-radius: 0;
	border                 : solid 1px #c7d4da;
	border-top             : none;
}

@media (min-width: 1280px) {
	div.container-app {
		/*margin-right: 2.4rem;*/
	}
}

@media (min-width: 768px) and (max-width: 1279px) {
	div.container-app {
		margin-right: 1.6rem;
	}
}

@media (max-width: 767px) {
	div.container-app {
		margin-right: 2.4rem;
	}
}

.paper-root {
	/* box-shadow: 0px 4px 6px -2px rgb(90 85 85 / 20%) !important */
	box-shadow: 0px 3px 12px #d2d2d2 !important;
}

.w-20 {
	width: 20%;
}

.result-negative-v2 {
	background-color: lime;
	border-radius   : 4px;
	padding         : 4px;
	min-width       : 40px;
	min-height      : 30px;
	text-align      : center;
}

.result-positive-v2 {
	background-color: red;
	color           : white;
	border-radius   : 4px;
	padding         : 4px;
	min-width       : 40px;
	min-height      : 30px;
	text-align      : center;
}

.result-inconclusive-v2 {
	background-color: #FF7500;
	color           : white;
	border-radius   : 4px;
	padding         : 4px;
	min-width       : 40px;
	min-height      : 30px;
	text-align      : center;
}

.result-default-v2 {
	background-color: #e6e6e6;
	border-radius   : 4px;
	padding         : 4px;
	min-width       : 40px;
	min-height      : 30px;
	text-align      : center;
}

.w-30 {
	width: 30%;
}

.react-tel-input {
	width        : 100% !important;
	height       : 43px !important;
	box-shadow   : 0px 3px 12px #d2d2d2 !important;
	margin-top   : -2px;
	border-radius: 4px !important;

}

.react-tel-input .selected-flag {
	margin-left: 5px !important;
}

.react-tel-input .country-list {
	left      : 5px !important;
	top       : 46px !important;
	/* z-index: 100 !important; */
}

.form-control {
	height   : inherit !important;
	font-size: 16px;
	border   : none !important;
}

.react-tel-input .flag-dropdown {
	display        : flex;
	justify-content: center;
	align-items    : center;
	border         : none !important;
	padding-left   : 3px;
}

.w-15 {
	width: 15%;
}

.w-10 {
	width: 10%;
}

.w-5 {
	width: 5%;
}

.w-35 {
	width: 35%;
}

.w-65 {
	width: 65%
}

.w-60 {
	width: 65%
}

.cursor-pointer {
	cursor: pointer;
}

.w-40 {
	width: 40%;
}

body {
	font-size: small !important;
}